<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <MemberForm ref="editForm" :initial="initial" v-show="edit" class="area-content"></MemberForm>
      <div class="area-content" v-if="detail" v-show="!edit">
        <Descriptions
          :title="detail.name"
          :list="basicList"
          :detail="detail"
          :bordered="false"
          :column="5"
          layout="horizontal"
        ></Descriptions>
        <a-tabs class="tabs">
          <a-tab-pane key="1" tab="基础信息">
            <Descriptions
              title="基础信息"
              :list="basicOtherList"
              :detail="detail"
              :bordered="false"
              :column="5"
            ></Descriptions>
            <Descriptions
              title="毕业院校及家庭情况"
              :list="schoolFamilyList"
              :detail="detail"
              :bordered="false"
              :column="5"
            ></Descriptions>
            <div class="descriptions-item-label">紧急联络人（直系亲属为主）</div>
            <EmergencyContact v-model="detail.contact_person" :show="true"></EmergencyContact>
          </a-tab-pane>
          <a-tab-pane key="2" tab="人事记录">
            <Descriptions :list="personnelRecordsList" :detail="detail" :bordered="false" :column="1"></Descriptions>
          </a-tab-pane>
          <a-tab-pane key="3" tab="用户行为">
            <div class="duration-box flex-box justify-center">
              <div class="duration-list flex-box" v-if="detail.duration">
                <div class="duration-item">
                  <div class="duration-circle">{{ detail.duration.service_duration }}</div>
                  <div class="duration-title">服务时长 (小时)</div>
                </div>
                <div class="duration-item">
                  <div class="duration-circle">{{ detail.duration.activity_duration }}</div>
                  <div class="duration-title">活动时长 (小时)</div>
                </div>
                <div class="duration-item">
                  <div class="duration-circle">{{ detail.duration.train_duration }}</div>
                  <div class="duration-title">培训时长 (小时)</div>
                </div>
              </div>
            </div>
            <div class="tabel-title">结缘品核销状态</div>
            <a-table
              ref="souvenirTable"
              :columns="souvenirColumns"
              :row-key="(record) => record.id"
              :data-source="souvenirList"
            ></a-table>
            <div class="tabel-title">共修统计</div>
            <a-table
              ref="culTable"
              :columns="culColumns"
              :row-key="(record) => record.id"
              :data-source="culList"
            ></a-table>
          </a-tab-pane>
          <a-tab-pane key="4" tab="注册小组变更记录">
            <div>
              <div class="time-box" v-if="registerRecords && registerRecords.length > 0">
                <div class="time-line" v-for="record in registerRecords" :key="record.id">
                  <div class="time-date">{{ record.create_time }}</div>
                  <div class="time-decoration">
                    <div class="time-dot"></div>
                  </div>
                  <div class="time-content">{{ record.content }}</div>
                </div>
              </div>
              <a-empty description="暂无变更记录" v-else />
            </div>
          </a-tab-pane>
          <a-tab-pane key="5" tab="专业组变更记录">
            <div>
              <div class="time-box" v-if="professionalRecords && professionalRecords.length > 0">
                <div class="time-line" v-for="record in professionalRecords" :key="record.id">
                  <div class="time-date">{{ record.create_time }}</div>
                  <div class="time-decoration">
                    <div class="time-dot"></div>
                  </div>
                  <div class="time-content">{{ record.content }}</div>
                </div>
              </div>
              <a-empty description="暂无变更记录" v-else />
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <a-space class="footer">
      <template v-if="edit">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </template>
      <template v-else>
        <a-button @click="back">返回</a-button>
        <a-button type="primary" @click="handleEdit">编辑</a-button>
      </template>
    </a-space>
  </div>
</template>

<script>
import {
  sexOptions,
  // registerCatOption,
  // specialCatOptions,
  conversionOptions,
  disciplinedOptions,
  channelOptions,
  carOptions,
  educationOptions,
  marriageOptions,
  bodyOptions,
  volunStatusOptions,
  receiveStatusOptions,
  templeteList,
  adminTempleteList,
  groupTypeOptions,
} from "../../../common/hr/volunteer";
import { dataConvertOptions, getKeyTitle, isEmpty, timestampConvertString } from "../../../common/js/tool";
import MemberForm from "./components/MemberForm.vue";
import Descriptions from "./components/Descriptions.vue";
import { getSystemRoleName } from "../../../permission/subsystem";
import EmergencyContact from "./components/EmergencyContact.vue";

const regex = /【([^】]+)】/g; // 匹配{}： /\{([^}]+)\}/g

export default {
  name: "MemberDetail",
  components: {
    MemberForm,
    Descriptions,
    EmergencyContact,
  },
  data() {
    return {
      edit: false,
      detail: null,
      initial: null,
      basicList: [],
      basicOtherList: [],
      schoolFamilyList: [],
      personnelRecordsList: [],
      columns: [
        {
          title: "核销人名称",
          dataIndex: "user_id",
          customRender: (text) => {
            if (text) {
              text = JSON.parse(text);
              return <open-data type="userName" openid={text[0]} />;
            } else {
              return "-";
            }
          },
        },
        { title: "手机号码", dataIndex: "mobile" },
        {
          title: "核销类别",
          customRender: (text) => {
            return <span>{getSystemRoleName(text.system, text.privilege)}</span>;
          },
        },
      ],
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
        showSizeChanger: true,
      },
      souvenirColumns: [
        { title: "结缘品类型", dataIndex: "name" },
        {
          title: "核销状态",
          customRender: (text) => {
            return <span>{getKeyTitle(receiveStatusOptions, text.status, "value", "label") || ""}</span>;
          },
        },
        {
          title: "核销时间",
          dataIndex: "update_time",
          customRender: (text) => {
            return <span>{text || "-"}</span>;
          },
        },
      ],
      souvenirList: [],
      culColumns: [
        {
          title: "共修类型",
          customRender: (text) => {
            let { meditation } = text;
            return <span>{meditation?.name}</span>;
          },
        },
        {
          title: "单位",
          customRender: (text) => {
            let { number, meditation } = text;
            return (
              <span>
                {number}({meditation?.unit})
              </span>
            );
          },
        },
      ],
      culList: [],
      groupOptions: [],
      registerRecords: [],
      professionalRecords: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/volunteer/${this.id}`,
          method: "GET",
          params: {
            expand: "volunteerGroup,volunteerStatus,professionalGroup,duration,lastTime",
          },
        }).then((res) => {
          this.detail = res;
          if (this.$route.query.edit) {
            this.handleEdit();
          } else {
            this.getDescriptionsList();
          }
        });
      }
    },
    getDescriptionsList() {
      this.getBasicDescriptions();
      this.getBasicOtherDescriptions();
      this.getSchoolFamilyList();
      this.getPersonnelRecordsList();
      this.getSouvenir();
      this.getCultivation();
      this.getRecord();
    },
    getBasicDescriptions() {
      let { professionalGroup, register_status } = this.detail;
      let basicList = [];
      basicList.push(
        {
          key: "file_number",
          label: "档案编号",
        },
        {
          key: "id_card",
          label: "身份证号",
        },
        {
          key: "sex",
          label: "性别",
          options: sexOptions,
          select: true,
        },
        {
          label: register_status == 8 ? "原注册小组" : "注册小组",
          slot: (detail) => {
            return <span>{detail.volunteerGroup?.name || "-"}</span>;
          },
        },
        {
          key: "register_status",
          label: "注册小组志工状态",
          options: volunStatusOptions,
          select: true,
        },
        {
          label: "注册时间",
          slot: (detail) => {
            return <span>{timestampConvertString(detail.register_time, "yyyy-MM-dd")}</span>;
          },
        },
        {
          key: "tel",
          label: "手机号码",
        },
        {
          key: "age",
          label: "年龄",
        }
      );
      professionalGroup?.forEach((item, index) => {
        index++;
        basicList.push(
          {
            label: `专业组${index}`,
            slot: () => {
              return <span>{item.volunteerGroup?.name || "-"}</span>;
            },
          },
          {
            label: `专业组${index}志工状态`,
            slot: () => {
              let { volunteer_status } = item;
              if (volunteer_status) {
                return <span>{this.getSelectText(volunStatusOptions, volunteer_status)}</span>;
              } else {
                return <span>-</span>;
              }
            },
          }
        );
      });
      this.basicList = basicList;
    },
    getBasicOtherDescriptions() {
      let { is_convert, channel_status, vehicle } = this.detail;
      let basicOtherList = [];
      basicOtherList.push(
        // {
        //   key: "register_post",
        //   label: "注册小组职务",
        //   options: registerCatOption,
        //   select: true,
        // },
        // {
        //   key: "speciality_post",
        //   label: "专业小组职务",
        //   options: specialCatOptions,
        //   select: true,
        // },
        {
          key: "id_card_address",
          label: "身份证地址",
        },
        {
          label: "通讯地址",
          slot: (detail) => {
            let { communicate_province, communicate_address } = detail;
            if (!isEmpty(communicate_province) && !isEmpty(communicate_address)) {
              return <span>{communicate_province.replace(/,/g, "") + communicate_address}</span>;
            } else {
              return <span>-</span>;
            }
          },
        },
        {
          key: "is_convert",
          label: "是否皈依",
          options: conversionOptions,
          select: true,
        }
      );
      if (is_convert == 2) {
        basicOtherList.push({
          key: "convert_temple",
          label: "皈依寺院",
        });
      }
      basicOtherList.push(
        {
          key: "disciplined_status",
          label: "受戒情况",
          options: disciplinedOptions,
          select: true,
        },
        {
          key: "channel_status",
          label: "报名渠道",
          options: channelOptions,
          select: true,
        }
      );
      if (channel_status != 3) {
        basicOtherList.push({
          key: "channel",
          label: "报名渠道",
        });
      }
      basicOtherList.push({
        key: "vehicle",
        label: "自有车辆情况",
        options: carOptions,
        select: true,
      });
      if (vehicle != 1) {
        basicOtherList.push({
          key: "vehicle_number",
          label: "车牌号",
        });
      }
      basicOtherList.push(
        {
          key: "sleep_time",
          label: "最近一次转为休眠时间",
        },
        {
          key: "personal_img",
          label: "个人证件照",
          img: true,
        },
        {
          key: "id_card_front_img",
          label: "身份证正面照片",
          img: true,
        },
        {
          key: "id_card_back_img",
          label: "身份证反面照片",
          img: true,
        },
        {
          label: "志工特长",
          slot: (detail) => {
            let { speciality, other_speciality } = detail;
            if (speciality) {
              speciality = speciality.split(",");
              let res = speciality.map((item) => {
                return <a-tag style="margin-bottom: 6px;">{item}</a-tag>;
              });
              if (speciality.includes("其他")) {
                res.push(<a-tag style="margin-bottom: 6px;">{other_speciality}</a-tag>);
              }
              return res;
            } else {
              return <span>-</span>;
            }
          },
        }
      );

      this.basicOtherList = basicOtherList;
    },
    getSchoolFamilyList() {
      let { health } = this.detail;
      let schoolFamilyList = [];
      schoolFamilyList.push(
        {
          key: "education",
          label: "最高学历",
          options: educationOptions,
          select: true,
        },
        {
          key: "graduate_school",
          label: "毕业院校",
        },
        {
          key: "major_studied",
          label: "专业",
        },
        {
          key: "certificate",
          label: "职业资质证书",
        },
        {
          key: "work_unit",
          label: "工作单位",
          textarea: true,
        },
        {
          key: "marriage",
          label: "婚姻情况",
          options: marriageOptions,
          select: true,
        },
        {
          key: "health",
          label: "身体情况",
          options: bodyOptions,
          select: true,
        }
      );
      if (health) {
        schoolFamilyList.push({
          key: "notice",
          label: "身体情况备注",
        });
      }
      this.schoolFamilyList = schoolFamilyList;
    },
    getPersonnelRecordsList() {
      let personnelRecordsList = [];
      personnelRecordsList.push(
        {
          key: "personnel_records",
          label: "人事记录",
          rich: true,
        },
        {
          key: "change_record",
          label: "志工信息变更记录",
          rich: true,
        },
        {
          key: "train_record",
          label: "培训、修学记录",
          rich: true,
        },
        {
          key: "reward",
          label: "志工荣誉",
          rich: true,
        },
        {
          key: "violation",
          label: "违纪及重点关注情况",
          rich: true,
        },
        {
          key: "duties",
          label: "志工团小组担任职务",
          rich: true,
        },
        {
          key: "attendance_record",
          label: "2021年起志工考勤记录",
          rich: true,
        }
      );
      this.personnelRecordsList = personnelRecordsList;
    },
    // 结缘品
    getSouvenir() {
      let id = this.detail.id;
      if (id) {
        this.$axios({
          url: `/admin/volunteer/souvenir-record`,
          method: "GET",
          params: {
            volunteer_id: id,
          },
        }).then((res) => {
          this.souvenirList = res.data;
        });
      }
    },
    // 共修统计
    getCultivation() {
      this.$axios({
        url: "/admin/volunteer-meditation-record",
        method: "GET",
        params: {
          sort: "-id",
          expand: "volunteer,meditation",
          "filter[volunteer_id]": this.detail.id,
        },
      }).then((res) => {
        this.culList = res.data;
      });
    },
    // 小组变更记录
    async getRecord() {
      try {
        await this.getGroup();
        let records = await this.getTotalRecord();
        // console.log("records:", records);
        let registerRecords = [],
          professionalRecords = [];
        records?.forEach((record) => {
          switch (record.type) {
            case 1:
              registerRecords.push(record);
              break;
            case 2:
              professionalRecords.push(record);
              break;
          }
        });
        this.getRecordContent(registerRecords);
        this.getRecordContent(professionalRecords);
        this.registerRecords = registerRecords;
        this.professionalRecords = professionalRecords;
      } catch (err) {
        console.log("err:", err);
      }
    },
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      })
        .then((res) => {
          this.groupOptions = dataConvertOptions(res.data);
          return res.data;
        })
        .catch((err) => {
          return err;
        });
    },
    getTotalRecord() {
      return this.$axios({
        url: "/admin/volunteer-change-record",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
          "filter[volunteer_id]": this.detail.id,
        },
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err;
        });
    },
    // 获取变更记录内容文本
    getRecordContent(records) {
      records?.forEach((record) => {
        let res = "";
        let { template, group_id: group, volunteer_status: status, details, type } = record;
        if (details) {
          details = JSON.parse(details);
          const { group_id: old_group, volunteer_status: old_status, flag = 0, text = null } = details;
          if (flag) {
            res = "数据迁移：\n" + text;
            record.flag = flag;
          } else {
            if ([13, 14, 15, 16].includes(template)) {
              // 13: 注册小组 14: 专业小组add 15: 专业小组update 16: 专业小组delete
              // 后台变更
              let admin_temple;
              if (template == 14) {
                admin_temple = 4;
              } else if (template == 16) {
                admin_temple = 5;
              } else if ([13, 15].includes(template)) {
                if (group != old_group && status != old_status) {
                  // 两者都变更
                  admin_temple = 1;
                } else if (group != old_group) {
                  // 小组变更
                  admin_temple = 2;
                } else if (status != old_status) {
                  // 志工变更
                  admin_temple = 3;
                }
              }
              if (admin_temple) {
                let target = adminTempleteList.find((item) => item.key == admin_temple);
                res = this.dealTempleContent(target, { group, old_group, status, old_status, group_type: type });
              }
            } else {
              let target = templeteList.find((item) => item.key == template);
              res = this.dealTempleContent(target, { group, old_group, status, old_status, group_type: type });
            }
          }
          record.content = res;
        }
      });
    },
    // 处理变更模板
    dealTempleContent(template, { group, old_group, status, old_status, group_type }) {
      if (!template) {
        return template;
      }
      let groupOptions = this.groupOptions;
      return template?.content.replace(regex, (target, type) => {
        let option;
        switch (type) {
          case "group":
            option = groupOptions.find((item) => item.value == group);
            break;
          case "status":
            option = volunStatusOptions.find((item) => item.value == status);
            break;
          case "old_group":
            option = groupOptions.find((item) => item.value == old_group);
            break;
          case "old_status":
            option = volunStatusOptions.find((item) => item.value == old_status);
            break;
          case "group_type":
            option = groupTypeOptions.find((item) => item.value == group_type);
            break;
        }
        return type == "group_type" ? option?.label || "" : `【${option?.label || ""}】`;
      });
    },
    getSelectText(options, value) {
      return getKeyTitle(options, value, "value", "label");
    },
    handleEdit() {
      this.initial = this.detail;
      this.edit = true;
    },
    handleSave() {
      this.$refs.editForm.validate().then((form) => {
        this.$axios({
          url: `/admin/volunteer/${this.id}`,
          method: "PATCH",
          data: form,
        }).then(() => {
          this.$message.success("保存成功");
          this.getDetail();
          this.handleCancel();
        });
      });
    },
    handleCancel() {
      if (this.$route.query.edit) {
        this.back();
      } else {
        this.edit = false;
        this.initial = null;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.area {
  overflow-x: hidden;
}
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
.tabs {
  width: 100%;
  overflow: visible;
}
.descriptions-item-label {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
}
.duration-box {
  margin: 24px 0;
}
.duration-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(:first-of-type) {
    margin-left: 240px;
  }
}
.duration-circle {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #999999;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
}
.duration-title {
  text-align: center;
  margin-top: 10px;
}
.tabel-title {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}

.time-box {
  .time-line {
    display: flex;
    line-height: 28px;
    &:not(:last-child) {
      margin-bottom: 10px;
      .time-decoration::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 19px;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background-color: #1890ff;
      }
    }
    .time-date {
      flex-shrink: 0;
      white-space: nowrap;
    }
    .time-decoration {
      flex-shrink: 0;
      position: relative;
      margin: 0 24px;
      padding-top: 9px;
      .time-dot {
        width: 10px;
        height: 10px;
        border: 2px solid #1890ff;
        background-color: #ffffff;
        box-sizing: border-box;
        border-radius: 50%;
        align-self: center;
      }
    }
    .time-content {
      white-space: pre-line;
    }
  }
}
</style>
